import React, { useState, useEffect } from "react";
import ServiceNavigation from "../components/ServiceNavigation.jsx";
import axios from "axios";
import { Auth, API } from "aws-amplify";
import {
  AppLayout,
  Alert,
  BreadcrumbGroup,
  Flashbar,
  Button,
  Form,
  FormField,
  Input,
  Textarea,
  Header,
  SpaceBetween,
  Container,
  Link,
  HelpPanel,
  Checkbox,
  Modal,
  Box,
} from "../aws-ui-components";
import "../styles/form.scss";

import { API_BASE_URL } from "../constants/StageConfig.js";

// Constants
const defaultFormData = {
  adminEmail: "",
  displayAccountName: "",
  accountName: "",
  whitelistedEmailDomains: [],
};


// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default function CreateSitePage(props) {
  const [toolsIndex, setToolsIndex] = useState(0);
  const [toolsOpen, setToolsOpen] = useState(false);

  return (
    <AppLayout
      navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
      breadcrumbs={<Breadcrumbs />}
      content={
        <Content
          // Changes the Help panel content when the user clicks an 'info' link
          replaceToolsContent={(index) => {
            setToolsIndex(index);
            setToolsOpen(true);
          }}
        />
      }
      contentType="form"
      toolsHide={false}
      tools={Tools[toolsIndex]}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      toolsOpen={toolsOpen}
      navigationOpen={true}
    />
  );
}

function ValidateEmail(email) {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email
  );
}

function ValidateSubdomain(subdomain) {
  return /^([a-z0-9]+)$/.test(subdomain);
}

function ValidateFormData(data) {
  const isValid =
    ValidateEmail(data.adminEmail) &&
    ValidateSubdomain(data.accountName) &&
    data.displayAccountName &&
    !(data.whitelistedEmailDomains.length == 0);
  return isValid;
}

function ConstructUniqueEmailAddress(amazonLogin, siteName) {
  return `${amazonLogin}+embrace-${siteName}@amazon.com`
}

// The content in the main content area of the App layout
const Content = (props) => {
  const [formData, setFormData] = useState(defaultFormData);
  const [apiLoading, setApiLoading] = useState(false);
  const [successVisible, setSuccessVisible] = React.useState(false);
  const [errorVisible, setErrorVisible] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [acceptedTos, setAcceptedTos] = useState(false);
  const [tosModalVisible, setTosModalVisible] = useState(false);

  const [user, setUser] = useState(null);
  const [userLogin, setUserLogin] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const [token, setToken] = useState(null);

  useEffect(() => {
    setIsAuthenticating(true);
    Auth.currentAuthenticatedUser()
      .then((usr) => {
        setUser(usr.attributes);
        setIsAuthenticating(false);

        const userLogin = JSON.parse(usr.attributes.identities)[0].userId;
        const userEmail = usr.attributes.email;

        setUserEmail(userEmail);
        setUserLogin(userLogin);

        setFormData({ ...formData, adminEmail: ConstructUniqueEmailAddress(userLogin, 'XXX') });
      })
      .catch(() => {
        console.log("Error authenticating user");
        setIsAuthenticating(false);
      });
  }, []);

  function getToken() {
    return Auth.currentSession()
      .then((session) => session)
      .catch((err) => console.log(err));
  }

  const handleSubmit = () => {
    // Check for accepted Terms of Service
    if (!acceptedTos) {
      showError(
        `To proceed, you must read and accept the terms of service of AWS Embrace!`
      );
      return;
    }

    // Validate
    if (ValidateFormData(formData)) {
      setApiLoading(true);

      getToken().then((userToken) => {
        const jwtToken = userToken.idToken.jwtToken;
        setToken(jwtToken);
        const postBody = {
          ...formData,
          adminLogin: userLogin,
        };

        axios
          .post(API_BASE_URL + "/onboard", postBody, {
            headers: { Authorization: jwtToken },
          })
          .then((res) => {
            setFormData(defaultFormData);
            setSuccessVisible(true);
            setApiLoading(false);
          })
          .catch((err) => {
            showError(
              `Oops! Something went wrong during your registration request. \
              Please make sure that you entered valid values. When in doubt, \
              get in contact with your admin.\
              Error: ${JSON.stringify(err.response.data)}
              `
            );
            setApiLoading(false);
          });
      });
    } else {
      showError(
        "The values you entered are not valid. Please check the info boxes to make sure\
       you entered the correct values."
      );
    }
  };

  /**
   * Displays the error badge with a custom message.
   * @param {string} message The message as a string
   */
  const showError = (message) => {
    setErrorMessage(message);
    setErrorVisible(true);
  };

  return (
    <SpaceBetween size="xs">
      <Modal
        onDismiss={() => setTosModalVisible(false)}
        visible={tosModalVisible}
        closeAriaLabel="Close"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link">Cancel</Button>
              <Button
                variant="primary"
                onClick={() => setTosModalVisible(false)}
              >
                Ok
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="AWS Embrace Terms of Service"
      >
        <p>Please read the following points carefully.</p>
        <div>
          <ol>
            <li>
              The AWS Embrace service is in <b>beta</b> with limited support and{" "}
              <b>no operative SLA</b>.
            </li>
            <li>
              You can only publish content that is <b>classified as public</b>.
              The site owner is responsible for ensuring compliance. We are
              currently in the process of working with AppSec to get the
              authentication mechanism and service approved.
            </li>
            <li>
              Utilize AWS Embrace solely with customers that have an{" "}
              <b>active NDA with Amazon/AWS</b>. Communicate that the tool is in
              beta and in active development.
            </li>
            <li>
              You will carefully read the user guide and join the
              #aws-embrace-interest slack channel to stay informed.
            </li>
          </ol>
        </div>
      </Modal>

      {/* <Flashbar
        items={[
          {
            type: "info",
            dismissable: false,
            content: (
              <>
                <b>Please read before registering!</b>
                <br />
                AWS Embrace is currently in <b>beta preview</b>. We are not
                (yet) an official tool and are currently undergoing the AppSec
                approval process. You may use this service with select customers
                under NDA. Please read our{" "}
                <Link
                  variant="primary"
                  color="inverted"
                  onFollow={() => setTosModalVisible(true)}
                >
                  Terms of Service
                </Link>
                .
              </>
            ),
          },
        ]}
      /> */}

<Flashbar
        items={[
          {
            type: "info",
            dismissable: false,
            content: (
              <>
                Thank you for your interest in AWS Embrace. The beta is now closed for AppSec review and we are not accepting additional users to onboard at this time. Please reach out to the AWS Embrace support team to be placed on the wait-list.</>
            ),
          },
        ]}
      />

      <Alert
        onDismiss={() => setSuccessVisible(false)}
        visible={successVisible}
        dismissAriaLabel="Close"
        dismissible
        type="success"
      >
        We successfully received for registration request. Keep an eye on your
        email inbox for further communication.
      </Alert>
      <Alert
        onDismiss={() => setErrorVisible(false)}
        visible={errorVisible}
        dismissAriaLabel="Close"
        dismissible
        type="error"
      >
        {errorMessage}
      </Alert>

      <Form
        header={<Header variant="h1">Create a new AWS Embrace site</Header>}
        actions={
          // located at the bottom of the form
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => setFormData(defaultFormData)}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              loading={apiLoading}
              variant="primary"
              disabled={true}
            >
              Create site
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="vertical" size="l">
          <Container header={<Header variant="h2">Site configuration</Header>}>
            <SpaceBetween size="l">
              <FormField
                label="Admin email address"
                stretch={true}
                info={
                  <Link
                    variant="info"
                    onFollow={() => props.replaceToolsContent(1)}
                  >
                    Info
                  </Link>
                }
                description="A unique email address of the AWS Embrace account admin. It will be autogenerated based on your subdomain. You can add additional admins later."
              >
                <Input
                  placeholder={"example@amazon.com"}
                  value={formData.adminEmail}
                  disabled={true}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      adminEmail: e.detail.value,
                    })
                  }
                />
              </FormField>

              <FormField
                label="Account name"
                stretch={true}
                info={
                  <Link
                    variant="info"
                    onFollow={() => props.replaceToolsContent(2)}
                  >
                    Info
                  </Link>
                }
                description="A friendly name to describe your account. This is typically the name of your customer account (e.g. 'Daimler')."
              >
                <Input
                  placeholder={"Example Corp"}
                  value={formData.displayAccountName}
                  disabled={true}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      displayAccountName: e.detail.value,
                    })
                  }
                />
              </FormField>

              <FormField
                label="Subdomain"
                stretch={true}
                info={
                  <Link
                    variant="info"
                    onFollow={() => props.replaceToolsContent(3)}
                  >
                    Info
                  </Link>
                }
                description="A uniquely identifiable, lowercase, URL-compliant string that is used to route traffic to your embrace page (e.g. 'daimler'). Please refrain from choosing generic, non-descriptive subdomains like 'ab'."
              >
                <Input
                  placeholder={"exampledomain"}
                  value={formData.accountName}
                  disabled={true}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      accountName: e.detail.value,
                      adminEmail: ConstructUniqueEmailAddress(userLogin, e.detail.value)
                    })
                  }
                />
              </FormField>

              <FormField
                label={<span>Allow-listed email domains</span>}
                info={
                  <Link
                    variant="info"
                    onFollow={() => props.replaceToolsContent(4)}
                  >
                    Info
                  </Link>
                }
                description="You must provide a list of allow-listed email domains that your customers use to access the public page with."
                constraintText="Specify email domains in the form '@amazon.com', each in a new line."
                stretch={true}
              >
                <Textarea
                  placeholder={"@amazon.com\n@example.com"}
                  disabled={true}
                  value={formData.whitelistedEmailDomains.join("\n")}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      whitelistedEmailDomains: e.detail.value.split("\n"),
                    })
                  }
                />
              </FormField>

              <FormField
                label={<span>Terms of Service</span>}
                info={
                  <Link
                    variant="info"
                    onFollow={() => props.replaceToolsContent(5)}
                  >
                    Info
                  </Link>
                }
              >
                <Checkbox
                  checked={acceptedTos}
                  onChange={({ detail }) => setAcceptedTos(detail.checked)}
                  disabled={true}
                >
                  I confirm that I have read and accepted the{" "}
                  <Link onFollow={() => setTosModalVisible(true)}>
                    AWS Embrace Terms of Service
                  </Link>{" "}
                  and will not publish any confidential data on AWS Embrace.
                </Checkbox>
              </FormField>
            </SpaceBetween>
          </Container>
        </SpaceBetween>
      </Form>
    </SpaceBetween>
  );
};

// Breadcrumb content
const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: "AWS Embrace",
        href: "#/",
      },
      {
        text: "Create Site",
        href: "#/create",
      },
    ]}
  />
);

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
  <HelpPanel header={<h2>AWS Embrace</h2>}>
    <p>
      You must create a new AWS Embrace account to start administrating your
      micropage. Please follow the instruction on this page and enter the
      corresponding information.
    </p>
  </HelpPanel>,
  <HelpPanel header={<h2>Admin email address</h2>}>
    <p>
      We will generate a unique email address for the main site admin for your AWS
      Embrace account. All major correspondence and login information will be
      handled through this email address. It depends on the subdomain you choose
      and uses the "+" character to distinguish between different embrace sites
      registered for a single Amazon employee.
    </p>
    <p>
      You can register additional users for administrating your micropage later
      on in the CMS environment.
    </p>
  </HelpPanel>,
  <HelpPanel header={<h2>Account name</h2>}>
    <p>
      Please choose a friendly name that will be displayed across your AWS
      Embrace page. This name should be the name of the AWS customer your are
      responsible for in your account team.
    </p>
    <p>
      <b>Example:</b> If you are managing the micropage for the Daimler account,
      you will likely put <i>Daimler</i> into this field.
    </p>
  </HelpPanel>,
  <HelpPanel header={<h2>Subdomain</h2>}>
    <p>
      This field uniquely identifies your AWS Embrace account and serves as a
      subdomain name to route requests to your respective micropage.
    </p>
    <p>
      <b>Example:</b> If you are managing the micropage for the Daimler account,
      you will likely put <i>daimler</i> into this field. Accordingly, your
      resulting customer-facing domain will be{" "}
      <i>{"<subdomain>.embrace.tools.aws.dev"}</i>.
    </p>
    <p>
      Please note that you should not use any special characters or whitespaces
      in this field. Only URL-compliant characters should be used.
    </p>
  </HelpPanel>,
  <HelpPanel header={<h2>Allow-listed email domains</h2>}>
    <div>
      <p>
        You must list any email domains used by your customers to allow them to
        access the AWS Embrace page by logging in with their corporate email
        address.
      </p>
      <p>
        AWS Embrace uses Lambda@Edge authentication which allows customers to
        enter their email address and in case it matches with one of the
        allow-listed domains, they will get sent an email with a signed URL to
        access the content. This will set an anonymous cookie, which is valid
        for the remaining calendar month.
      </p>
    </div>
  </HelpPanel>,
  <HelpPanel header={<h2>AWS Embrace Terms of Service</h2>}>
    <div>
      <p>
        You need to agree to our terms of service to be able to use AWS Embrace.
      </p>
    </div>
  </HelpPanel>,
];
