import React from 'react';
import { withRouter } from 'react-router-dom';
import { SideNavigation, Badge } from '../aws-ui-components';

// Component ServiceNavigation is the Side Navigation component that is used in BasicLayout, CreateForm, ServiceHomepage, and Table flows.
// Implement like this: <ServiceNavigation />
function ServiceNavigation(props) {
  // If the provided link is empty, do not redirect pages
  function onFollowHandler(ev) {
    // FIXED External link functionality
    if (!ev.detail.external) {
      ev.preventDefault();
      if (ev.detail.href) {
        props.history.push(ev.detail.href.substring(1));
      }
    }
  }

  return (
    <SideNavigation
      header={{ text: 'AWS Embrace', href: '#/' }}
      items={items}
      activeHref={`#${props.location.pathname}`}
      onFollow={onFollowHandler}
    />
  );
}

const items = [
  {
    type: 'section',
    text: 'Administration',
    items: [
      { type: 'link', text: 'Create site', href: '#/create' },
      { type: 'link', text: 'My sites', href: '#/my-sites'}
    ]
  },
  {
    type: 'section',
    text: 'Explore',
    items: [
      { type: 'link', text: 'All Embrace sites', href: '#/sites' },
      { type: 'link', text: 'Analytics', href: '#/analytics-all'}
    ]
  },
  {
    type: 'section',
    text: 'Help',
    items: [
      { type: 'link', text: 'User guide', href: "https://quip-amazon.com/p7gAAzc4JIb3/The-AWS-Embrace-Handbook", external: true },
      { type: 'link', text: 'Slack channel', href: "https://amzn-aws.slack.com/archives/C020V8EBKSL", external: true },
      { type: 'link', text: 'Bug report / feedback', href: "https://t.corp.amazon.com/create/templates/5d7392f3-fccf-4f65-af42-a1fcce368456", external: true},
    ]
  }
];

export default withRouter(ServiceNavigation);
