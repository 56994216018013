import React from "react";
import {
  Link,
  Icon,
  SpaceBetween,
  Button,
  Popover,
  StatusIndicator,
} from "../../aws-ui-components";
import formatBytes from "../../utils/utils";

export const COLUMN_DEFINITIONS = [
  {
    id: "displayName",
    header: "Name",
    cell: (item) => (
      <span>
        <Icon name="file" size="normal" variant="normal" /> {item.fileName}
      </span>
    ),
    sortingField: "displayName",
  },
  {
    id: "publicURL",
    width: 200,
    cell: (item) =>
      item.publicURL ? (
        <SpaceBetween direction="horizontal" size="xs">
          <Popover
            size="small"
            position="top"
            triggerType="custom"
            dismissButton={false}
            content={
              <StatusIndicator type="success">
                Public URL copied
              </StatusIndicator>
            }
          >
            <Button
              variant="inline-icon"
              iconName="copy"
              onClick={() => navigator.clipboard.writeText(item.publicURL)}
            />
          </Popover>
          <Link external href={item.publicURL} target="_blank">
            {"Open"}
          </Link>
        </SpaceBetween>
      ) : (
        <i>Unknown URL</i>
      ),
    header: "Public URL",
    sortingField: "publicURL",
  },
  {
    id: "size",
    header: "Size",
    cell: (item) => formatBytes(item.size),
    sortingField: "size",
    width: 140,
  },
  {
    id: "lastModifiedAt",
    header: "Last modified at",
    cell: (item) => new Date(item.lastModified).toLocaleString(),
    sortingField: "lastModifiedAt",
    width: 200,
  },
];

export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: "Main properties",
    options: [],
  },
];

export const PAGE_SELECTOR_OPTIONS = [];

export const CUSTOM_PREFERENCE_OPTIONS = [
  { value: "table", label: "Table" },
  { value: "cards", label: "Cards" },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 30,
  visibleContent: ["displayName", "publicURL", "size", "lastModifiedAt"],
  wraplines: false,
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value,
};
