import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { Spinner, Alert, Container, Header, Button, Icon, SpaceBetween } from '../aws-ui-components'
import { Auth } from "aws-amplify";

const QuickSightEmbedding = require('amazon-quicksight-embedding-sdk');

import { GENERATE_GENERAL_DASHBOARD_URL } from "../constants/StageConfig";

export default function EmbraceGeneralSiteAnalytics(props) {
    const [loading, setLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [dashboardUrl, setDashboardUrl] = useState(null);

    const embedContainerRef = useRef(null);

    function getToken() {
        return Auth.currentSession()
            .then((session) => session)
            .catch((err) => console.log(err));
    }

    function renderDashboard(embedUrl) {
        console.log(embedUrl);
        var options = {
            url: embedUrl,
            container: embedContainerRef.current,
            scrolling: "no",
            height: "AutoFit",
            loadingHeight: "700px",
            width: "100%",
            locale: "en-US",
            footerPaddingEnabled: false,
            printEnabled: true,
            sheetTabsDisabled: false,
            undoRedoDisabled: false,
            resetDisabled: false
        };
        const dashboard = QuickSightEmbedding.embedDashboard(options);
    }

    function refreshEmbeddedDashboard() {

        // Authenticate
        setLoading(true)
        Auth.currentAuthenticatedUser()
        .then((usr) => {

        // Get User Token
            getToken().then((userToken) => {
                const jwtToken = userToken.idToken.jwtToken;

                // Get the media files for the current user
                axios
                    .get(`${GENERATE_GENERAL_DASHBOARD_URL}`, {
                        headers: { Authorization: jwtToken },
                    })
                    .then((res) => {
                        const data = res.data;
                        setDashboardUrl(data.embedUrl);
                        setIsAuthorized(true);
                        setLoading(false);

                        renderDashboard(data.embedUrl);
                    })
                    .catch((err) => {
                        console.log(err);
                        setLoading(false);
                    });
            });
        })
        .catch((err) => {
            console.log("Error authenticating user", err);
            setLoading(false)
        });
    };

    useEffect(() => {
        refreshEmbeddedDashboard();
    }, []);

   

    return (!loading||isAuthorized) ? (
        isAuthorized ? (
            <div>
                <Container
                      header={
                        <Header
                          variant="h2"
                          description={`All AWS Embrace Sites`}
                          actions={
                            <SpaceBetween
                            direction="horizontal"
                            size="xs"
                          >
                            {/* <Button>Refresh</Button> */}
                          </SpaceBetween>
                          }
                        >
                          Analytics Dashboard
                        </Header>
                      }
                >
                    <div ref={embedContainerRef}/>
                </Container>
                
            </div>
        ) : ( // !isAuthorized
                <Alert
                  visible={true}
                  type="warning"
                >
                <b>Authorization error</b>: You are not authorized to access this content.
              </Alert>
        )
    ) : ( // loading && !isAuthorized
        <Spinner />
    )

}
