import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Auth } from "aws-amplify";
import { 
  AppLayout, 
  BreadcrumbGroup, 
  Header, 
  Form, 
  SpaceBetween, 
  Button,
  Container,
  FormField,
  StatusIndicator,
  Checkbox,
  ProgressBar
} from "../aws-ui-components";
import '../styles/form.scss';
import ServiceNavigation from '../components/ServiceNavigation';
import formatBytes from '../utils/utils';
import { MEDIA_BASE_URL } from "../constants/StageConfig";

const PART_SIZE = 8 * 1024 * 1024 // 8MB
const MAX_FILE_SIZE = 5 * 1024 * 1024 * 1024 // 5GB


const Breadcrumbs = (props) => (
  <BreadcrumbGroup 
    items={[
      {text: "AWS Embrace", href:"#/"},
      {text: "My sites", href: "#/my-sites"},
      {text: props.siteId, href: "#/sites/" + props.siteId},
      {text: "Upload", href:"#/upload"}
    ]} 
    ariaLabel="Breadcrumbs" 
  />
);

function FormContent(props) {
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const inputFile = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0.0);
  const [progressStatus, setProgressStatus] = useState("")
  const [progressDescription, setProgressDescription] = useState("Upload in progress...")
  const [progressResultText, setProgressResultText] = useState("")
  const [progressResultButtonText, setProgressResultButtonText] = useState("")
  const [acceptedToS, setAcceptedToS] = useState(false);
  const [tosErrorText, setTosErrorText] = useState("");
  const [missingFileErrorText, setMissingFileErrorText] =  useState("");
  const [errorText, setErrorText] =  useState("");


  useEffect(() => {
    setMissingFileErrorText("")
    if (isSelected && selectedFile.size > MAX_FILE_SIZE) setMissingFileErrorText(`${selectedFile.name} exceeds the maximum upload size (${formatBytes(MAX_FILE_SIZE)}).`)
  }, [isSelected, selectedFile]);

  useEffect(() => {
    setTosErrorText("")
  }, [acceptedToS]);

  const selectFileHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true)
  }

  const onSelectFileClick = () => {
    inputFile.current.click()
  }

  function getToken() {
    return Auth.currentSession()
        .then((session) => session)
        .catch((err) => console.log(err));
}

  const handleProgressResultButtonClick = () => {
    if (progressResultButtonText == "Retry") {
      handleUploadSubmission();
    }
  }
  
  const handleUploadSubmission = () => {
    if (!acceptedToS) setTosErrorText("Please accept the Terms of Service to continue upload.");
    if (!isSelected) setMissingFileErrorText("Please select a file to upload");

    if (acceptedToS && isSelected && selectedFile.size <= MAX_FILE_SIZE) {
      setErrorText("")
       // Authenticate
       setIsUploading(true);
       Auth.currentAuthenticatedUser()
       .then((usr) => {
       // Get User Token
           getToken().then((userToken) => {
               const jwtToken = userToken.idToken.jwtToken;
               fireMultipartUpload(jwtToken);
           });
       })
       .catch((err) => {
           console.log("Error authenticating user", err);
           setIsUploading(false);
       });
    }
  }

  const fireMultipartUpload = async (jwtToken) => {
    setProgressStatus("in-progress")
    setProgress(0.0)
    try {
      const ACCOUNT = props.siteId
      const resInit = await axios.post(`${MEDIA_BASE_URL}/initMultipartUpload/${ACCOUNT}`, {"filename": selectedFile.name}, {headers: { Authorization: jwtToken }})
      const multiUploadId = resInit.data.UploadId
      const multiKey = resInit.data.Key
      const numParts = Math.ceil(selectedFile.size / PART_SIZE)
      const getMultiPromises = []
      for (let i = 0; i < numParts; i++) {
        getMultiPromises.push(
          axios.post(`${MEDIA_BASE_URL}/getMultipartSignedUrl/${ACCOUNT}`, {"key": multiKey, "partNumber": i+1, "uploadId": multiUploadId}, {headers: { Authorization: jwtToken }})
        )
      }
      const resSignedUrls = await Promise.all(getMultiPromises)
      const uploadUrls = resSignedUrls.map(part => { // order should be preserved by promise.all
        return part.data.uploadURL
      })
      const uploadPromises = []
      for (let index = 0; index < numParts; index++) {
        const start = index * PART_SIZE
        const end = (index+1) * PART_SIZE
        const blob = index < (numParts-1) ? selectedFile.slice(start, end) : selectedFile.slice(start)
        uploadPromises.push(axios.put(uploadUrls[index], blob))
      }
      const resUpload = await allprogress(uploadPromises, (p) => {
        setProgress(p.toFixed(2))
      })
      const resParts = resUpload.map((part, index) => ({
        ETag: part.headers.etag,
        PartNumber: index + 1
      }))
      const resComplete = await axios.post(`${MEDIA_BASE_URL}/completeMultipartUpload/${ACCOUNT}`, {"parts": resParts, "key": multiKey, "uploadId": multiUploadId}, {headers: { Authorization: jwtToken }})
      setProgressStatus("success")
      setProgressDescription("")
      setProgressResultText("Upload complete, "+formatBytes(selectedFile.size))
      setIsUploading(false);
    } catch (error) {
      setErrorText("An error occurred.")
      setProgressStatus("error")
      setProgressDescription("")
      setProgressResultText("Upload failed")
      setProgressResultButtonText("Retry")
      setIsUploading(false);
    }
  }

  const allprogress = (proms, progress_cb) => {
    let d = 0;
    progress_cb(0);
    for (const p of proms) {
      p.then(() => {    
        d++;
        progress_cb( (d * 100) / proms.length );
      });
    }
    return Promise.all(proms);
  }

  return (
    <form onSubmit={e => e.preventDefault()}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            { progressStatus=="success" ? (
                <Button formAction="submit" variant="primary" href={`#/sites/${props.siteId}`}>Close</Button>
              ) : (
                <div>
                <Button formAction="none" variant="link" href={`#/sites/${props.siteId}`} disabled={isUploading}>
                  Cancel
                </Button>
                <Button formAction="submit" variant="primary" onClick={handleUploadSubmission} loading={isUploading}>Upload</Button>
                </div>
              )
            }
          </SpaceBetween>
        }
        errorText={errorText}
        header={
          <Header
            variant="h1"
            description="Select a file you want to upload to your library."
          >
            Upload
          </Header>
        }
      >
        <Container
          header={
            <Header variant="h2">File</Header>
          }
        >
          <SpaceBetween direction="vertical" size="l">
            {/* File */}
            <FormField label="Select file" description="The selected file will be uploaded to your library." constraintText={`Maximum file size: ${formatBytes(MAX_FILE_SIZE)}`} errorText={missingFileErrorText}>
              <input type="file" style={{ display: "none" }} multiple="" ref={inputFile} onChange={selectFileHandler} />
              <Button variant="normal" iconName="upload" onClick={onSelectFileClick}>Choose file</Button>
            </FormField>
            {isSelected ? (
                  <FormField label={<span><StatusIndicator type='success'/><strong>{selectedFile.name}</strong></span>} 
                             description={<span>
                              <StatusIndicator type="info" colorOverride="grey"/>File size: {formatBytes(selectedFile.size)}<br/>
                              <StatusIndicator type="info" colorOverride="grey"/>Last modified: {new Date( selectedFile.lastModified).toLocaleString()}
                            </span>} />

            ):(<div></div>)}
            
          </SpaceBetween>
        </Container>
        <Container>
          <FormField label={<span>Terms of Service</span>} errorText={tosErrorText}>
            <Checkbox
              checked={acceptedToS}
              onChange={({ detail }) => setAcceptedToS(detail.checked)}
            >
              I confirm that I have read and accepted the{" "}
              <strong>
                AWS Embrace Terms of Service
              </strong>{" "}
              and will not publish any confidential data on AWS Embrace.
            </Checkbox>
          </FormField>
        </Container>
        {progressStatus ? (
           <Container>
           <ProgressBar
             status={progressStatus}
             value={progress}
             description={progressDescription}
             label="Uploading file"
             resultText={progressResultText}
             resultButtonText={progressResultButtonText}
             onResultButtonClick={() => handleProgressResultButtonClick()}
           />
         </Container>
        ) :(<div></div>) 
        }
       
      </Form>
    </form>
  );
}

export default function SiteCreateUploadPage(props) {
  const siteId = props.match.params.id;

  return (
    <AppLayout
      navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
      breadcrumbs={<Breadcrumbs siteId={siteId} />}
      content={<FormContent siteId={siteId} />}
      contentType="default"
      toolsHide={true}
    />
  );
}