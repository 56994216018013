import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage.jsx";
import Authentication from "./components/Authentication.jsx";
import CreateForm from "./pages/CreateSitePage.jsx";
import EmbraceSitesPage from "./pages/EmbraceSitesPage.jsx";
import MyEmbraceSitesPage from "./pages/MyEmbraceSitesPage";
import SiteAnalyticsPage from "./pages/SiteAnalyticsPage.jsx";
import "@amzn/awsui-global-styles/polaris.css";

import Amplify from "aws-amplify";
import { Auth, Hub } from "aws-amplify";
import aws_exports from "./aws-exports";
import SiteDetailPage from "./pages/SiteDetailPage.jsx";
import { IDENTITY_POOL_ID, USER_POOL_APP, USER_POOL_DOMAIN, USER_POOL_ID, USER_POOL_REGION } from "./constants/StageConfig.js";
import SiteEditPage from "./pages/SiteEditPage.jsx";
import SiteUploadPage from "./pages/SiteCreateUploadPage";

// Dynamically set the redirect SignIn depending on environment
let redirectSignIn;
let redirectSignOut;
let userPoolsId;
let webClientId;

if (process.env.NODE_ENV === 'development') {
  redirectSignIn  = 'http://localhost:3000/';
  redirectSignOut = 'http://localhost:3000/';
}

if (process.env.NODE_ENV === 'production') {
  redirectSignIn = 'https://create.admin.embrace.tools.aws.dev/';
  redirectSignOut = 'https://create.admin.embrace.tools.aws.dev/';
}

const oauth = {
  domain: USER_POOL_DOMAIN,
  scope: aws_exports.oauth.scope,
  redirectSignIn: redirectSignIn,
  redirectSignOut: redirectSignOut,
  responseType: aws_exports.oauth.responseType
};
aws_exports.oauth = oauth;
aws_exports.aws_user_pools_id = USER_POOL_ID;
aws_exports.aws_user_pools_web_client_id = USER_POOL_APP;
aws_exports.aws_cognito_identity_pool_id = IDENTITY_POOL_ID;
aws_exports.aws_cognito_region = USER_POOL_REGION;
Amplify.configure(aws_exports);

// Class App is the "output" generated on every build,
// it is what you will see on the webpage.
const App = () => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [requestedPage, setRequestedPage] = useState("");

  useEffect(() => {
    if (requestedPage) {
      window.location.replace(requestedPage);
    }
  }, [requestedPage]);

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoadingState(true);
      setIsAuthenticating(false);
    }
  }, [isAuthenticated]);

  Hub.listen("auth", (data) => {
    switch (data.payload.event) {
      case "signIn":
        setIsAuthenticated(true);
        break;
      case "signIn_failure":
        setIsAuthenticated(false);
        break;
      case "customOAuthState": {
        // redirect to original page
        setRequestedPage(data.payload.data);
        break;
      }

      default:
        break;
    }
  });

  useEffect(() => {
    if (window.location.href.indexOf("?code=") !== -1) {
      setIsAuthenticating(true);
    }
    Auth.currentAuthenticatedUser()
      .then((usr) => {
        setIsAuthenticated(true);
        setUser(usr);
      })
      .catch(() => {
        setIsAuthenticated(false);
      });
  }, []);

  const handleSignIn = () => {
    const from = window.location ? window.location.hash : undefined;

    // customState is used to support direct page links. Is returned in the OAuth2 callback
    setIsAuthenticating(true);
    Auth.federatedSignIn({ customProvider: "AmazonFederate" });
  };

  if (isAuthenticated) {
    return (
      <div>
        <Route exact path="/" component={LandingPage} />
        <Route path="/service-home" component={LandingPage} />
        <Route path="/create" component={CreateForm} />
        <Route path="/sites" exact component={EmbraceSitesPage} />
        <Route path="/my-sites" component={MyEmbraceSitesPage} />
        <Route path="/analytics-all" component={SiteAnalyticsPage} />
        <Route path="/sites/:id" exact render={(props) => (<SiteDetailPage {...props} />)} />
        <Route path="/sites/:id/edit" exact render={(props) => (<SiteEditPage {...props} />)} />
        <Route path="/sites/:id/upload" exact render={(props) => (<SiteUploadPage {...props} />)} />        
      </div>
    );
  } else {
    return(
      <div>
        <Authentication isAuthenticating={isAuthenticating} isLoadingState={isLoadingState} isAuthenticated={isAuthenticated} onSignIn={handleSignIn}/>
      </div>
    )
  }
};

export default App;
