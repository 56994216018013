import React, { useState, useEffect } from 'react';
import 'axios';
import ServiceNavigation from '../components/ServiceNavigation.jsx';
import {
  COLUMN_DEFINITIONS,
  CONTENT_SELECTOR_OPTIONS,
  PAGE_SELECTOR_OPTIONS,
  CUSTOM_PREFERENCE_OPTIONS,
  DEFAULT_PREFERENCES
} from '../resources/table/sites-table-config.jsx';

import {
  AppLayout,
  Header,
  SpaceBetween,
  Box,
  BreadcrumbGroup,
  Button,
  Flashbar,
  CollectionPreferences,
  Pagination,
  TextFilter,
  Table,
  FormField,
  RadioGroup,
  HelpPanel,
  Icon
} from '../aws-ui-components';
import {Auth, API} from "aws-amplify";

import { useCollection } from '@awsui/collection-hooks';
import axios from 'axios';
import { API_BASE_URL } from '../constants/StageConfig.js';


// Component TableView is a skeleton of a Table using AWS-UI React components.
export default function EmbraceSitesPage() {
  return (
    <AppLayout
      navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
      breadcrumbs={<Breadcrumbs />}
      content={<DetailsTable />}
      contentType="table"
      //tools={Tools}
      toolsHide={true}
    />
  );
};

const DetailsTable = () => {
  // Below are variables declared to maintain the table's state.
  // Each declaration returns two values: the first value is the current state, and the second value is the function that updates it.
  // They use the general format: [x, setX] = useState(defaultX), where x is the attribute you want to keep track of.
  // For more info about state variables and hooks, see https://reactjs.org/docs/hooks-state.html.
  const [embraceSites, setEmbraceSites] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [loading, setLoading] = useState(true);

  const [token, setToken] = useState(null);

  function getToken() {
    return Auth.currentSession()
      .then(session => session)
      .catch(err => console.log(err));
  }

  // a utility to handle operations on the data set (such as filtering, sorting and pagination)
  // https://polaris.a2z.com/develop/guides/collection_hooks/
  const { items, actions, collectionProps, filterProps, paginationProps, filteredItemsCount } = useCollection(
    embraceSites,
    {
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      filtering: {
        noMatch: (
          <Box textAlign="center" color="inherit">
            <b>No matches</b>
            <Box color="inherit" margin={{ top: 'xxs', bottom: 's' }}>
              No results match your query
            </Box>
            <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
          </Box>
        )
      }
    }
  );

  // Get the token from Amplify Auth first, then call API with this token to get data
  useEffect(() => {
    getToken().then(
      userToken => {
        const jwtToken = userToken.idToken.jwtToken;
        setToken(jwtToken);
        console.log(jwtToken);
        axios.get(API_BASE_URL + '/sites', {headers: {'Authorization': jwtToken}})
          .then(res => {
            const data = res.data;
            const filteredData = data.sites.filter(site => site.provisioningState == 'SUCCEEDED');
            setEmbraceSites(filteredData);
            setLoading(false);
          })
          .catch(err => {
            alert('Error fetching API', err);
          })
      });
  }, []);

  // Keeps track of how many distributions are selected
  function headerCounter(selectedItems, items) {
    return selectedItems.length
      ? `(${selectedItems.length} of ${items.length})`
      : `(${items.length})`;
  }

  function filterCounter(count) {
    return `${count} ${count === 1 ? 'match' : 'matches'}`;
  }

  return (
    <Table
      {...collectionProps}
      columnDefinitions={COLUMN_DEFINITIONS}
      visibleColumns={preferences.visibleContent}
      items={items}
      loading={loading}
      loadingText="Loading resources"
      resizableColumns={true}
      header={
        <TableHeader
          selectedDistributions={selectedItems}
          counter={headerCounter(selectedItems, embraceSites)}
        />
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          pageSizePreference={{
            title: 'Page size',
            options: PAGE_SELECTOR_OPTIONS
          }}
          visibleContentPreference={{
            title: 'Select visible columns',
            options: CONTENT_SELECTOR_OPTIONS
          }}
          wrapLinesPreference={{
            label: 'Wrap lines',
            description: 'Check to see all the text and wrap the lines'
          }}
          customPreference={(value, setValue) => (
            <FormField stretch={true} label="View as">
              <RadioGroup
                value={value}
                onChange={({ detail }) => setValue(detail.value)}
                items={CUSTOM_PREFERENCE_OPTIONS}
              />
            </FormField>
          )}
        />
      }
      wrapLines={preferences.wrapLines}
      /* selectedItems={selectedItems}
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
      selectionType="single" */
      pagination={<Pagination {...paginationProps} />}
      filter={
        <TextFilter
          {...filterProps}
          countText={filterCounter(filteredItemsCount)}
          filteringPlaceholder="Search sites"
        />
      }
    />
  );
};

// Table header content, shows how many distributions are selected and contains the action stripe
const TableHeader = ({ selectedDistributions, counter }) => {
  const isOnlyOneSelected = selectedDistributions.length === 1;

  return (
    <Header
      variant="h2"
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="s">
          <Button iconName="refresh" onClick={() => window.location.reload()}/>
          {/* <Button disabled={!isOnlyOneSelected}> View details </Button> */}
          <Button href="#/create" variant="primary">
            Create Site
          </Button>
        </SpaceBetween>
      }
    >
      AWS Embrace Sites
    </Header>
  );
};

// Breadcrumb content
const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: 'AWS Embrace',
        href: '#/service-home'
      },
      {
        text: 'Sites',
        href: '#/sites'
      }
    ]}
  />
);