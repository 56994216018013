import React from 'react'
import { StatusIndicator } from '../aws-ui-components'

export default function DeploymentStateIndicator(props) {
    const configMap = {
        'SUCCEEDED': {
          type: 'success',
          text: 'Deployed'
        },
        'IN_PROGRESS': {
          type: 'in-progress',
          text: 'In progress'
        },
        'FAILED': {
          type: 'error',
          text: 'Failed'
        }
      };
    
      const config = configMap[props.deploymentState];
      if(config == undefined) { return <StatusIndicator type="stopped">Not deployed</StatusIndicator> }
    
      return (
        <StatusIndicator type={config.type}>{config.text}</StatusIndicator>
      )
}