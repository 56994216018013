/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React from 'react';
import ServiceNavigation from '../components/ServiceNavigation.jsx';
import {
  AppLayout,
  Button,
  ColumnLayout,
  FormField,
  Icon,
  Select,
  Container,
  Header,
  Box,
  Grid,
  SpaceBetween,
  Link,
} from '../aws-ui-components';

import '../styles/servicehomepage.scss'; 

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default function LandingPage() {
  return (
    <AppLayout
      disableContentPaddings={true}
      navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
      content={<Content />}
      contentType="default"
      navigationOpen={false}
      toolsHide={true}
    />
  );
};

// The content in the main content area of the App layout
const Content = () => (
  <Box padding="s" margin={{ bottom: 'l' }}>
    <Grid className="custom-home__header" gridDefinition={[{ colspan: { xxs: 12 } }]}>
      <Box padding={{ vertical: 'xxl' }}>
        <Grid
          gridDefinition={[
            { offset: { l: '2', xxs: '1' }, colspan: { l: '8', xxs: '10' } },
            { colspan: { xxs: 10, s: 6, l: 5, xl: 6 }, offset: { xxs: 1, l: 2 } },
            { colspan: { xxs: 10, s: 4, l: 3, xl: 2 }, offset: { xxs: 1, s: 0 } }
          ]}
        >
          <Box fontWeight="light" padding={{ top: 'xs' }}>
            <span className="custom-home__category">AWS Marketing / Sales</span>
          </Box>

          <div className="custom-home__header-title">
            <Box variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
              AWS Embrace
            </Box>
            <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
              Gated microsites for your customers
            </Box>
            <Box variant="p" fontWeight="light">
              <span className="custom-home__header-sub-title">
                AWS Embrace is an AWS-internal service that allows account teams to administrate their own customized microsites for their customers.
              </span>
            </Box>
          </div>

          <Container>
            <SpaceBetween size="s">
              <Box variant="h2" padding="n">
                Important update on AWS Embrace availabililty
              </Box>
              <Box variant="p">
                Thank you for your interest in AWS Embrace! The beta is now closed for AppSec review and we are not accepting additional users to onboard at this time. Please reach out to the AWS Embrace support team to be placed on the wait-list.
              </Box>
            </SpaceBetween>
          </Container>
        </Grid>
      </Box>
    </Grid>

    <Box padding={{ top: 'xxl' }}>
      <Grid
        gridDefinition={[
          { colspan: { xxs: 10, s: 6, l: 5, xl: 6 }, offset: { xxs: 1, l: 2 } },
          { colspan: { xxs: 10, s: 4, l: 3, xl: 2 }, offset: { xxs: 1, s: 0 } }
        ]}
      >
        <div>
          <SpaceBetween size="l">
            {/*
            <div>
              <Box fontSize="heading-xl" fontWeight="normal" variant="h2">
                How it works
              </Box>
              <Container>
                <div className="custom-home-image__placeholder" />
              </Container>
            </div>
            */}

            <div>
              <Box fontSize="heading-xl" fontWeight="normal" variant="h2">
                How it works
              </Box>
              <Container>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img src={`/images/embrace_graphic_01.svg`} width="90%" style={{ alignSelf: 'center'}}/>
                </div>
               
              </Container>
            </div>

            <div>
              <Box fontSize="heading-xl" fontWeight="normal" variant="h2">
                Benefits and features
              </Box>
              <Container>
                <ColumnLayout columns={2} variant="text-grid">
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      White glove experience
                    </Box>
                    <Box variant="p">
                      Provide your customers with a customized space that provides them with information and resources tailored to their needs.
                    </Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Gated access
                    </Box>
                    <Box variant="p">
                      Access to the microsite is protected by a passwordless authentication mechanism that ensures that only employees of your customer can access the content.
                    </Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Accelerate organic AWS adoption
                    </Box>
                    <Box variant="p">
                      Provide your customer with comprehensive information on how to get started and who to connect with to get their project running. Build trust by highlighting key references.
                    </Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Reporting and analytics
                    </Box>
                    <Box variant="p">
                      Uncover the engagement within your customer with the content provided on the microsite. Derive customer interest and align your communication to their needs.
                    </Box>
                  </div>
                </ColumnLayout>
              </Container>
            </div>

            <div>
              <Box fontSize="heading-xl" fontWeight="normal" variant="h2">
                Use cases
              </Box>
              <Container>
                <ColumnLayout columns={2} variant="text-grid">
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Event schedule
                    </Box>
                    <Box variant="p">
                      Create a customized event schedule for your customers, so they can find the right ones easily and join straight from the AWS Embrace page.
                    </Box>
                    {/* <Link href="#" external>
                      Learn more
                    </Link> */}
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Team contact
                    </Box>
                    <Box variant="p">
                      Build trust with your customer by presenting your customer team online. Providing their roles enables the customers to contact the right people in your team.
                    </Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Training & Certification schedule
                    </Box>
                    <Box variant="p">
                      Account teams frequently use AWS Embrace to communicate customer-specific Training & Certification schedules. We provide several pre-built components in AWS Embrace you can use.
                    </Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      News and announcements
                    </Box>
                    <Box variant="p">
                      Use AWS Embrace as a platform to announce relevant service and feature releases to your customer, inform them of upcoming in-person events like re:invent or publish your own news articles tailered towards your customer.
                    </Box>
                  </div>
                </ColumnLayout>
              </Container>
            </div>
          </SpaceBetween>
        </div>

        <div className="custom-home__sidebar">
          <SpaceBetween size="xxl">
            <Container
              header={
                <Header variant="h2">
                  Getting started
                </Header>
              }
            >
              <ul className="custom-list-separator">
                <li>
                  <Link
                    href="https://w.amazon.com/bin/view/AWS_Embrace/"
                    target="_blank"
                  >
                     <Icon name="status-info" /> AWS Embrace Wiki
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://quip-amazon.com/p7gAAzc4JIb3/The-AWS-Embrace-Handbook"
                  >
                     <Icon name="file" /> Open the Embrace user guide
                  </Link>
                </li>
              </ul>
            </Container>
            
            <Container
              header={
                <Header variant="h2">
                  Contact the team
                </Header>
              }
            >
              <ul className="custom-list-separator">
                <li>
                  <Link href="mailto:aws-embrace@amazon.com" target="_blank">
                  <Icon name="envelope" /> Shoot us an email
                  </Link>
                </li>
                <li>
                  <Link href="https://amzn-aws.slack.com/archives/C020V8EBKSL" target="_blank">
                  <Icon name="contact" /> Join our Slack channel
                  </Link>
                </li>
                <li>
                  <Link href="https://t.corp.amazon.com/create/templates/1fbc24af-1e09-48fb-bb84-e4febbd646ce" target="_blank"> <Icon name="heart" /> Request a feature</Link>
                </li>
                <li>
                  <Link href="https://t.corp.amazon.com/create/templates/5d7392f3-fccf-4f65-af42-a1fcce368456" target="_blank">  <Icon name="bug" /> Report a bug / provide feedback </Link>
                </li>
              </ul>
            </Container>
           
          </SpaceBetween>
        </div>
      </Grid>
    </Box>
  </Box>
);
