import React from 'react';
import { Link, StatusIndicator } from '../../aws-ui-components';
import DeploymentStateIndicator from '../../components/DeploymentStateIndicator';
import ProvisioningStateIndicator from '../../components/ProvisioningStateIndicator';


export const COLUMN_DEFINITIONS = [
  {
    id: 'displayName',
    header: 'Site name',
    cell: item => item.displayAccountName,
    sortingField: 'displayName'
  },
  {
    id: 'domainNameEditor',
    cell: item => item.frontendDomain ? <Link external href={'https://' + item.frontendDomain.split(".")[0] + '.admin.embrace.tools.aws.dev' } target="_blank">Open</Link> : '',
    header: 'Editor site',
    sortingField: 'domainNameEditor'
  },
  {
    id: 'domainName',
    cell: item => item.frontendDomain ? <Link external href={'https://' + item.frontendDomain} target="_blank">{item.frontendDomain}</Link> : '',
    header: 'Public site',
    sortingField: 'domainName'
  },
  {
    id: 'adminEmail',
    header: 'Owner',
    cell: item => <Link external href={"https://phonetool.amazon.com/users/" + item.adminLogin}>{item.adminLogin}</Link>,
    sortingField: 'adminEmail'
  },
  {
    id: 'createdAt',
    header: 'Created at',
    cell: item => new Date(item.createdAt).toLocaleDateString(),
    sortingField: 'createdAt'
  },
  {
    id: 'provisioningState',
    header: 'Onboarding state',
    cell: item => <ProvisioningStateIndicator provisioningState={item.provisioningState}/>,
    sortingField: 'provisioningState'
  },
  {
    id: 'deploymentState',
    header: 'Deployment state',
    cell: item => <DeploymentStateIndicator deploymentState={item.deploymentState}/>,
    sortingField: 'deploymentState'
  },
  {
    id: 'lastDeployedAt',
    header: 'Last deployed at',
    cell: item => item.lastDeploymentAt ? new Date(item.lastDeploymentAt).toLocaleString() : '-',
    sortingField: 'lastDeployedAt'
  },
];

export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: 'Main properties',
    options: [
    ]
  }
];

export const PAGE_SELECTOR_OPTIONS = [
];

export const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

export const DEFAULT_PREFERENCES = {
  pageSize: 30,
  visibleContent: ['displayName', 'domainName', 'adminEmail', 'createdAt', 'lastDeployedAt'],
  wraplines: false,
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};
