import config from '../config.json'

// Parse stage from config file
const STAGE = config.stage;

/**
 * Gets the corresponding stage
 */
const stageConfig = config[STAGE];


/**
 * The base URL for the API to use, based on the current stage used with https prefix.
 */
export const API_BASE_URL = 'https://' + stageConfig.API_ENDPOINT;

/**
 * The user pool region
 */
export const USER_POOL_REGION = stageConfig.USER_POOL_REGION;

/**
 * The user pool ID to use for this stage
 */
export const USER_POOL_ID = stageConfig.USER_POOL_ID;

/**
 * The associated user pool domain for the user pool
 */
export const USER_POOL_DOMAIN = stageConfig.USER_POOL_DOMAIN;

/**
 * The identity pool id associated with the user pool
 */
export const IDENTITY_POOL_ID = stageConfig.IDENTITY_POOL_ID;

/**
 * The app client ID for the user pool
 */
export const USER_POOL_APP = stageConfig.USER_POOL_APP;

/**
 * The base URL for the media library api
 */
export const MEDIA_BASE_URL = API_BASE_URL + '/uploads';

/**
 * The base URL for the embed URL generation api
 */
export const GENERATE_DASHBOARD_BASE_URL = API_BASE_URL + '/analytics/generate-embed-url'

/**
 * The base URL for the embed URL generation api
 */
 export const GENERATE_GENERAL_DASHBOARD_URL = API_BASE_URL + '/analytics/generate-general-embed-url'