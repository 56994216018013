import React, { useEffect, useState } from "react";
import {
  Box,
  ColumnLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
  StatusIndicator,
  ProgressBar,
  Icon,
  Popover,
  Button,
  Badge,
  Spinner,
  Alert
} from "../aws-ui-components";
import axios from "axios";
import { Auth } from "aws-amplify";

import DeploymentStateIndicator from "./DeploymentStateIndicator";
import ProvisioningStateIndicator from "./ProvisioningStateIndicator";
import { API_BASE_URL } from "../constants/StageConfig";



const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box margin={{ bottom: "xxxs" }} color="text-label">
      {label}
    </Box>
    <div>{children}</div>
  </div>
);

export default function EmbraceSiteDetails(props) {
  const [siteDetails, setSiteDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  function getToken() {
    return Auth.currentSession()
      .then((session) => session)
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    // Authenticate
    Auth.currentAuthenticatedUser()
      .then((usr) => {
        // Get User Token
        getToken().then((userToken) => {
          const jwtToken = userToken.idToken.jwtToken;

          // Get the sites for the current user
          axios
            .get(API_BASE_URL + "/sites/" + props.accountName, {
              headers: { Authorization: jwtToken },
            })
            .then((res) => {
              const data = res.data;
              setSiteDetails(data);
              setIsAuthorized(true);
              setLoading(false);
            })
            .catch((err) => {
              // alert("Error fetching API", err);
              console.log(err);
              setLoading(false);
            });
        });
      })
      .catch((err) => {
        console.log("Error authenticating user", err);
      });
  }, []);

  return !loading ? (
    isAuthorized ? (
      <Container
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="primary" href={"#/sites/" + props.accountName + "/edit"}>Edit</Button>
            </SpaceBetween>
          }
        >
          {siteDetails.displayAccountName}
        </Header>
      }
    >
      <ColumnLayout columns={2} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="Subdomain / Site ID">
            {siteDetails.accountName}
          </ValueWithLabel>
          <ValueWithLabel label="Display name">
            {siteDetails.displayAccountName}
          </ValueWithLabel>
          <ValueWithLabel label="Admin login">
            {siteDetails.adminLogin}
          </ValueWithLabel>
          <ValueWithLabel label="Secondary admin logins">
            {siteDetails.secondaryAdminLogins ? siteDetails.secondaryAdminLogins.join(", ") : '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Admin email">
            {siteDetails.adminEmail}
          </ValueWithLabel>
          <ValueWithLabel label="Allow-listed email domains">
            <SpaceBetween size="xs">
              {siteDetails.whitelistedEmailDomains.map((emailDomain) => (
                <Badge key={emailDomain} color="blue">
                  {emailDomain}
                </Badge>
              ))}
            </SpaceBetween>
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Onboarding state">
            <ProvisioningStateIndicator provisioningState={siteDetails.provisioningState}/>
          </ValueWithLabel>
          <ValueWithLabel label="Creation date">
            {new Date(siteDetails.createdAt).toLocaleString()}
          </ValueWithLabel>
          <ValueWithLabel label="Public page">
            <Link href={'https://' + siteDetails.frontendDomain} external={true}>
              {siteDetails.frontendDomain}
            </Link>
          </ValueWithLabel>
          <ValueWithLabel label="Deployment state">
            <DeploymentStateIndicator
              deploymentState={siteDetails.deploymentState}
            />
          </ValueWithLabel>
          <ValueWithLabel label="Last deployed at">
            {siteDetails.lastDeploymentAt
              ? new Date(siteDetails.lastDeploymentAt).toLocaleString()
              : "-"}
          </ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
    ) : (
      <Alert
        visible={true}
        type="warning"
      >
      <b>Authorization error</b>: You are not authorized to access this content.
    </Alert>
    )
  ) : (
    <Spinner />
  );
}
