import React, { useEffect } from "react";
import { Box, Button, Container, Spinner } from "../aws-ui-components";
import '../styles/base.scss';
import Logo from '../assets/img/AWS_logo_RGB.png'

export default function Authentication(props) {
  // Show spinner only either when authenticating or when loading something
  const showSpinner = props.isAuthenticating || props.isLoadingState;

  // Show Sign in button only either when authenticating and loading or when not authenticated and not currently authenticating
  const showSignInButton =
    (props.isAuthenticating && props.isLoadingState) ||
    (!props.isAuthenticated && !props.isAuthenticating);

  const handleLogon = () => {
    props.onSignIn();
  };

  return (
    <div className="custom-auth__box__container">
      <Container className="custom-auth__box">
        <Box textAlign="center" variant="h1">
          <div>
            <img src={Logo} width={100} alt="" />
          </div>
          <div>
            <Box>AWS Embrace</Box>
          </div>
          {showSignInButton && (
            <div>
              <Button variant="primary" onClick={handleLogon}>
                Sign In
              </Button>
            </div>
          )}
          {showSpinner && <Spinner size="large" />}
        </Box>
      </Container>
    </div>
  );
}
