import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Auth } from "aws-amplify";
import {
    COLUMN_DEFINITIONS,
    DEFAULT_PREFERENCES
  } from '../resources/table/my-media-table-config';
import {
    SpaceBetween,
    Button,
    Spinner,
    Alert,
    Table,
    Box,
    Header,
    Modal,
    FormField,
    Popover,
    StatusIndicator,
    TextFilter,
    Pagination
} from '../aws-ui-components';
import { useCollection } from '@awsui/collection-hooks';
import { MEDIA_BASE_URL } from "../constants/StageConfig";


export default function EmbraceSiteMediaLibrary(props) {
    const [loading, setLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
    const [mediaFiles, setMediaFiles] = useState([])
    const [selectedItems, setSelectedItems] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false)

    function getToken() {
        return Auth.currentSession()
            .then((session) => session)
            .catch((err) => console.log(err));
    }

    function listFiles() {
        // Authenticate
        setLoading(true)
        Auth.currentAuthenticatedUser()
        .then((usr) => {
        // Get User Token
            getToken().then((userToken) => {
                const jwtToken = userToken.idToken.jwtToken;

                // Get the media files for the current user
                axios
                    .get(`${MEDIA_BASE_URL}/listUploads/${props.accountName}`, {
                        headers: { Authorization: jwtToken },
                    })
                    .then((res) => {
                        const data = res.data;
                        setMediaFiles(data.objects);
                        setSelectedItems([])
                        setIsAuthorized(true);
                        setLoading(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        setLoading(false);
                    });
            });
        })
        .catch((err) => {
            console.log("Error authenticating user", err);
            setLoading(false)
        });
    }

    function deleteFile() {
        // Authenticate
        setLoading(true)
        Auth.currentAuthenticatedUser()
        .then((usr) => {
        // Get User Token
            getToken().then((userToken) => {
                const jwtToken = userToken.idToken.jwtToken;
                // Get the media files for the current user
                axios
                    .post(`${MEDIA_BASE_URL}/deleteObject/${props.accountName}`,{"filename": selectedItems[0].fileName},  {headers: { Authorization: jwtToken }})
                    .then((res) => {
                        listFiles()
                        setIsAuthorized(true);
                        setShowDeleteModal(false)
                    })
                    .catch((err) => {
                        // alert("Error fetching API", err);
                        console.log(err);
                        setLoading(false);
                    });
            });
        })
        .catch((err) => {
            console.log("Error authenticating user", err);
            setLoading(false)
        });
    }

    function downloadFile() {
        // Authenticate
        setIsDownloading(true)
        Auth.currentAuthenticatedUser()
        .then((usr) => {
        // Get User Token
            getToken().then((userToken) => {
                const jwtToken = userToken.idToken.jwtToken;
                // Get the media files for the current user
                axios
                    .get(`${MEDIA_BASE_URL}/downloadObject/${props.accountName}`, {
                        headers: { Authorization: jwtToken },
                        params: {filename: selectedItems[0].fileName}
                    })
                    .then((res) => {
                        const link = document.createElement("a");
                        link.href = res.data.downloadURL;
                        document.body.appendChild(link)
                        link.click();
                        link.parentNode.removeChild(link);
                        setIsAuthorized(true);
                        setIsDownloading(false);
                    })
                    .catch((err) => {
                        // alert("Error fetching API", err);
                        console.log(err);
                        setIsDownloading(false);
                    });
            });
        })
        .catch((err) => {
            console.log("Error authenticating user", err);
            setIsDownloading(false)
        });
    }

    function headerCounter(selectedItems, items) {
        return selectedItems.length
          ? `(${selectedItems.length} of ${items.length})`
          : `(${items.length})`;
      }

    useEffect(() => {
        listFiles()
    }, []);

    // a utility to handle operations on the data set (such as filtering, sorting and pagination)
    // https://polaris.a2z.com/develop/guides/collection_hooks/
    const { items, actions, collectionProps, filterProps, paginationProps, filteredItemsCount } = useCollection(
        mediaFiles,
        {
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
        filtering: {
            noMatch: (
            <Box textAlign="center" color="inherit">
                <b>No matches</b>
                <Box color="inherit" margin={{ top: 'xxs', bottom: 's' }}>
                    No results match your query
                </Box>
                <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
            </Box>
            )
        }
        }
    );

    // Table header content, shows how many distributions are selected and contains the action stripe
    const TableHeader = () => {
        const isOnlyOneSelected = selectedItems.length === 1;
    
        return (
        <Header
            variant="h2"
            counter={headerCounter(selectedItems, mediaFiles)}
            actions={
            <SpaceBetween direction="horizontal" size="s">
                {/* <Button disabled={!isOnlyOneSelected}>View Details</Button> */}
                <Popover size="small" position="top" triggerType="custom" dismissButton={false} 
                content={<StatusIndicator type="success">Public URL copied</StatusIndicator>}>
                    <Button iconName="copy" disabled={!isOnlyOneSelected} onClick={() => navigator.clipboard.writeText(selectedItems[0].publicURL)}>Copy URL</Button>
                </Popover>
                <Button iconName="download" disabled={!isOnlyOneSelected} loading={isDownloading} onClick={() => downloadFile()}>Download</Button>
                <Button disabled={!isOnlyOneSelected} onClick={() => setShowDeleteModal(true)}>Delete</Button>
                <Button iconName="refresh" onClick={() => listFiles()} loading={loading} />
                <Button href={"#/sites/" + props.accountName + "/upload"} variant="primary" iconName="upload">Upload</Button>
            </SpaceBetween>
            }
        >
            Files
        </Header>
        );
    }

    const DeleteModal = () => {
        return (
            <Modal
                onDismiss={() => setShowDeleteModal(false)}
                visible={showDeleteModal}
                closeAriaLabel="Close modal"
                size="medium"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                            <Button variant="primary" onClick={() => deleteFile()} loading={loading}>Delete</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Delete file"
                >
                <FormField
                    label={`Do you want to delete the selected file?`}
                    description="This action cannot be undone."
                >
                    {/* <Input /> */}
                </FormField>
            </Modal>
        )
    }

    return (!loading||isAuthorized) ? (
        isAuthorized ? (
            <div>
                <Table
                    columnDefinitions={COLUMN_DEFINITIONS}
                    visibleColumns={preferences.visibleColumns}
                    items={items}
                    loading={loading}
                    loadingText="Loading files"
                    resizableColumns={true}
                    selectionType='single'
                    selectedItems={selectedItems}
                    onSelectionChange={({detail}) => setSelectedItems(detail.selectedItems)}
                    empty={
                        <Box textAlign="center" color="inherit">
                            <b>No files</b>
                            <Box
                                padding={{ bottom: "s" }}
                                variant="p"
                                color="inherit"
                            >
                                No files to display.
                            </Box>
                            <Button href={"#/sites/" + props.accountName + "/upload"} iconName="upload" >Upload file</Button>
                        </Box>
                    }
                    header={
                        <TableHeader
                        selectedFiles={selectedItems}
                            accountName={props.accountName}
                            listFiles={listFiles}
                            setSelectedItems={setSelectedItems}
                            isLoading={loading}
                            deleteFile={deleteFile}
                        />
                    }
                    filter={
                        <TextFilter
                        {...filterProps}
                        countText={`${filteredItemsCount} ${filteredItemsCount == 1 ? 'match' : 'matches'}`}
                        filteringPlaceholder="Search files"
                        />
                    }
                    pagination={<Pagination {...paginationProps} />}
                />
                <DeleteModal />
            </div>
        ) : ( // !isAuthorized
                <Alert
                  visible={true}
                  type="warning"
                >
                <b>Authorization error</b>: You are not authorized to access this content.
              </Alert>
        )
    ) : ( // loading && !isAuthorized
        <Spinner />
    )
}
