import React from "react";
import ServiceNavigation from '../components/ServiceNavigation.jsx';
import {
  // import the components that you use in the file here
  AppLayout,
  BreadcrumbGroup,
  HelpPanel,
  Icon,
  Container,
  Header,
  SpaceBetween,
  BarChart,
  Box,
  Button,
  PieChart,
  Flashbar,
  Link,
  LineChart
} from "../aws-ui-components";
import EmbraceGeneralSiteAnalytics from "../components/EmbraceGeneralSiteAnalytics.jsx";

// Component Basic is a skeleton of the basic App layout using AWS-UI React components.
export default function SiteAnalyticsPage() {
  return (
    <AppLayout
      navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
      breadcrumbs={<Breadcrumbs />} // Breadcrumbs element defined below
      content={<Content />} // Main content on the page, defined below
      contentType="default" // Sets default app layout settings for widths
      tools={Tools} // Tools panel content defined below
    />
  );
}

// Breadcrumb content
const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: "AWS Embrace",
        href: "#/",
      },
      {
        text: "Site Metrics",
        href: "#/analytics",
      },
    ]}
  />
);


// Main content area (fill it in with components!)
const Content = () => (
  <div>
    <SpaceBetween direction="vertical" size="m">
      <Header variant="h1">AWS Embrace Analytics</Header>

      <Flashbar
        items={[
          {
            type: "info",
            dismissable: true,
            content: (
              <>
                <b>Looking for analytics for your own Embrace site?</b>
                <br />
                This is the general analytics dashboard for measuring impact across all AWS Embrace sites. You will find more detailed analytics for your own sites under {" "}
                 <Link
                  variant="primary"
                  color="inverted"
                  href="#/my-sites"
                >
                  My sites
                </Link>
                {"."} 
              </>
            ),
          },
        ]}
      />

    <Flashbar
            items={[
              {
                type: "info",
                dismissable: false,
                content: (
                  <><b>Important</b>: Please note that due to the ongoing AppSec review, we have disabled tracking analytics for AWS Embrace temporarily. Starting from Sept. 14th 2022, no new usage metrics will be captured.</>
                ),
              },
            ]}
          />

      <EmbraceGeneralSiteAnalytics />
    </SpaceBetween>
  </div>
);

// Help panel content
const Tools = (
  <HelpPanel header={<h2>AWS Embrace</h2>}>
    <p>Track your site metrics and perform additional configuration</p>
  </HelpPanel>
);
