import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ServiceNavigation from "../components/ServiceNavigation.jsx";
import {
  AppLayout,
  Header,
  SpaceBetween,
  BreadcrumbGroup,
  Container,
  Spinner,
  TextContent,
  Tabs
} from "../aws-ui-components";
import { Auth } from "aws-amplify";
import EmbraceSiteDetails from "../components/EmbraceSiteDetails.jsx";
import EmbraceSiteAnalytics from "../components/EmbraceSiteAnalytics.jsx";
import EmbraceSiteMediaLibrary from "../components/EmbraceSiteMediaLibrary.jsx";

export default function SiteDetailPage(props) {

    const siteId = props.match.params.id;

  return (
    <AppLayout
      navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
      breadcrumbs={<Breadcrumbs siteId={siteId} />}
      content={<Content siteId={siteId} />}
      contentType="default"
      toolsHide={true}
    />
  );
}

// Breadcrumb content
const Breadcrumbs = (props) => (
  <BreadcrumbGroup
    items={[
      {
        text: "AWS Embrace",
        href: "#/service-home",
      },
      {
        text: "My sites",
        href: "#/my-sites",
      },
      {
        text: props.siteId,
        href: "#/sites/" + props.siteId,
      },
    ]}
  />
);

// Main content area (fill it in with components!)
const Content = (props) => {
  const [activeTab, setActiveTab] = useState("first")
  return (
    <div>
      <SpaceBetween direction="vertical" size="m">
        <Header variant="h1">Admin Panel - <b>{props.siteId}</b></Header>
        <Tabs
          activeTabId={activeTab}
          onChange={(e) => setActiveTab(e.detail.activeTabId)}
          tabs={[
            {
              label: "Details",
              id: "first",
              content: <EmbraceSiteDetails accountName={props.siteId}/>
            },
            {
              label: "Media Library",
              id: "second",
              content: <EmbraceSiteMediaLibrary accountName={props.siteId} />
            },
            {
              label: "Analytics",
              id: "third",
              content: <EmbraceSiteAnalytics accountName={props.siteId}/>
            }
          ]}
        />
      </SpaceBetween>
    </div>
  );
};
