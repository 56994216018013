import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ServiceNavigation from "../components/ServiceNavigation.jsx";
import {
  AppLayout,
  Header,
  SpaceBetween,
  BreadcrumbGroup,
  Button,
  Checkbox,
  Container,
  Form,
  ExpandableSection,
  Input,
  FormField,
  RadioGroup,
  Link,
  Textarea,
  Spinner,
  Icon,
  Alert,
} from "../aws-ui-components";
import axios from "axios";
import { Auth } from "aws-amplify";

import { API_BASE_URL } from "../constants/StageConfig";

export default function SiteEditPage(props) {
  const siteId = props.match.params.id;

  return (
    <AppLayout
      navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
      breadcrumbs={<Breadcrumbs siteId={siteId} />}
      content={<Content siteId={siteId} />}
      contentType="default"
      toolsHide={true}
    />
  );
}

// Breadcrumb content
const Breadcrumbs = (props) => (
  <BreadcrumbGroup
    items={[
      {
        text: "AWS Embrace",
        href: "#/service-home",
      },
      {
        text: "My sites",
        href: "#/my-sites",
      },
      {
        text: props.siteId,
        href: "#/sites/" + props.siteId,
      },
    ]}
  />
);

// Main content area (fill it in with components!)
const Content = (props) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [siteDetails, setSiteDetails] = useState({});
  const [whitelistedEmailDomains, setWhitelistedEmailDomains] = useState([]);
  const [secondaryAdminLogins, setSecondaryAdminLogins] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [alertMessage, setAlertMessage] = useState('');
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState('error');

  function getToken() {
    return Auth.currentSession()
      .then((session) => session)
      .catch((err) => console.log(err));
  };

  function showAlert(message, type) {
    setAlertMessage(message);
    setAlertType(type);
    setAlertVisible(true);
  }

  function handleSubmit() {
    setFormSubmitting(true);

    getToken().then((userToken) => {
      const jwtToken = userToken.idToken.jwtToken;
      const postBody = {
        accountName: props.siteId,
        whitelistedEmailDomains: whitelistedEmailDomains,
        secondaryAdminLogins: secondaryAdminLogins
      };

      axios
        .post(API_BASE_URL + "/sites/update-site-detail/" + props.siteId, postBody, {
          headers: { Authorization: jwtToken },
        })
        .then((res) => {
          const siteDetails = res.data;
          setSiteDetails(siteDetails);
          setWhitelistedEmailDomains(siteDetails.whitelistedEmailDomains);
          setFormSubmitting(false);
          showAlert('Your changes were saved successfully!', 'success')
        })
        .catch((err) => {
          console.log('Error submitting API call');
          setFormSubmitting(false);
          showAlert('There was a problem submitting your request', 'error')
        });
    });

  };

  useEffect(() => {
    // Authenticate
    Auth.currentAuthenticatedUser()
      .then((usr) => {
        // Get User Token
        getToken().then((userToken) => {
          const jwtToken = userToken.idToken.jwtToken;

          // Get the sites for the current user
          axios
            .get(API_BASE_URL + "/sites/" + props.siteId, {
              headers: { Authorization: jwtToken },
            })
            .then((res) => {
              const data = res.data;
              setSiteDetails(data);
              setWhitelistedEmailDomains(data.whitelistedEmailDomains);
              setSecondaryAdminLogins(data.secondaryAdminLogins ? data.secondaryAdminLogins : []);
              setIsAuthorized(true);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        });
      })
      .catch((err) => {
        console.log("Error authenticating user", err);
      });
  }, []);

  return !loading ? (
    isAuthorized ? (
      <SpaceBetween direction="vertical" size="m">
        <Alert
          onDismiss={() => setAlertVisible(false)}
          visible={alertVisible}
          dismissAriaLabel="Close"
          dismissible
          type={alertType}
        >
          {alertMessage}
        </Alert>
        <Header variant="h1">
          Edit site - <b>{props.siteId}</b>
        </Header>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" href={`#/sites/${props.siteId}`} >Cancel</Button>
                <Button variant="primary" onClick={handleSubmit} loading={formSubmitting}>Save changes</Button>
              </SpaceBetween>
            }
          >
            <Container
              header={<Header variant="h2">General site settings</Header>}
            >
              <SpaceBetween size="l">
                <FormField
                  label={<span>Secondary site administrators</span>}
                  description={
                    "Please specify the Amazon logins of allowed site administrators separated by comma"
                  }
                  constraintText="Specify the logins in the form 'jeff, ajassy, selipsky'."
                  stretch={false}
                >
                  <Input
                    value={secondaryAdminLogins.join(", ")}
                    onChange={(e) => setSecondaryAdminLogins(e.detail.value.split(", "))}
                  />
                </FormField>
                <FormField
                  label={<span>Allow-listed email domains</span>}
                  description="You must provide a list of allow-listed email domains that your customers can access the page with."
                  constraintText="Specify email domains in the form '@amazon.com', each in a new line."
                  stretch={false}
                >
                  <Textarea
                    placeholder={"@amazon.com\n@example.com"}
                    value={whitelistedEmailDomains.join("\n")}
                    onChange={(e) => setWhitelistedEmailDomains(e.detail.value.split("\n"))}
                    rows={whitelistedEmailDomains.length}
                  />
                </FormField>
              </SpaceBetween>
            </Container>
          </Form>
      </SpaceBetween>
    ) : (
      <Alert visible={true} type="warning">
        <b>Authorization error</b>: You are not authorized to access this
        content.
      </Alert>
    )
  ) : (
    <Spinner />
  );
};
