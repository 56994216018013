import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { Spinner, Alert, Container, Header, Button, Icon, SpaceBetween, Flashbar } from '../aws-ui-components'
import { Auth } from "aws-amplify";

const QuickSightEmbedding = require('amazon-quicksight-embedding-sdk');

import { GENERATE_DASHBOARD_BASE_URL } from "../constants/StageConfig";

export default function EmbraceSiteAnalytics(props) {
    const [loading, setLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [dashboardUrl, setDashboardUrl] = useState(null);

    const embedContainerRef = useRef(null);

    function getToken() {
        return Auth.currentSession()
            .then((session) => session)
            .catch((err) => console.log(err));
    }

    function renderDashboard(embedUrl) {
        console.log(embedUrl);
        var options = {
            url: embedUrl,
            container: embedContainerRef.current,
            scrolling: "no",
            height: "AutoFit",
            loadingHeight: "700px",
            width: "100%",
            locale: "en-US",
            footerPaddingEnabled: false,
            printEnabled: true,
            sheetTabsDisabled: false,
            undoRedoDisabled: false,
            resetDisabled: false
        };
        const dashboard = QuickSightEmbedding.embedDashboard(options);
    }

    function refreshEmbeddedDashboard() {

        // Authenticate
        setLoading(true)
        Auth.currentAuthenticatedUser()
        .then((usr) => {

        // Get User Token
            getToken().then((userToken) => {
                const jwtToken = userToken.idToken.jwtToken;

                // Get the media files for the current user
                axios
                    .get(`${GENERATE_DASHBOARD_BASE_URL}/${props.accountName}`, {
                        headers: { Authorization: jwtToken },
                    })
                    .then((res) => {
                        const data = res.data;
                        setDashboardUrl(data.embedUrl);
                        setIsAuthorized(true);
                        setLoading(false);

                        renderDashboard(data.embedUrl);
                    })
                    .catch((err) => {
                        console.log(err);
                        setLoading(false);
                    });
            });
        })
        .catch((err) => {
            console.log("Error authenticating user", err);
            setLoading(false)
        });
    };

    useEffect(() => {
        refreshEmbeddedDashboard();
    }, []);

   

    return (!loading||isAuthorized) ? (

        isAuthorized ? (
            <div>
                  <SpaceBetween size="xs">
                  <Flashbar
                        items={[
                        {
                            type: "info",
                            dismissable: false,
                            content: (
                            <><b>Important</b>: Please note that due to the ongoing AppSec review, we have disabled tracking analytics for AWS Embrace temporarily. Starting from Sept. 14th 2022, no new usage metrics will be captured.</>
                            ),
                        },
                        ]}
                    />
                    <Container
                        header={
                            <Header
                            variant="h2"
                            description={`Site ID: ${props.accountName}`}
                            actions={
                                <SpaceBetween
                                direction="horizontal"
                                size="xs"
                            >
                                {/* <Button>Refresh</Button> */}
                            </SpaceBetween>
                            }
                            >
                            Analytics Dashboard
                            </Header>
                        }
                    >
                        <div ref={embedContainerRef}/>
                    </Container>
                  </SpaceBetween>
               
                
            </div>
        ) : ( // !isAuthorized
                <Alert
                  visible={true}
                  type="warning"
                >
                <b>Authorization error</b>: You are not authorized to access this content.
              </Alert>
        )
    ) : ( // loading && !isAuthorized
        <Spinner />
    )

}
